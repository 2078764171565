import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";

// import "../scss/main.scss";

const GameTest = () => (
  // <Layout>
  <div>
    <Seo></Seo>
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
    <div className="game-embed">
      <Helmet>
        <script src="https://files.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js"></script>
      </Helmet>
      <iframe src="https://campaign.leadfamly.co.uk/get-to-know-us-memory-game_1?onlyflow=1&iframe=dontbreakout" width="1200" height="750"></iframe>
    </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default GameTest;
